import React, { useState, useRef, useEffect, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { ScreenContext } from '@components/Layout'
import gsap from 'gsap/gsap-core'

import media from '@styles/media'
import text from '@styles/text'
import colors from '@styles/colors'

import TextAnimation from '@components/TextAnimation'

import BoxPNG from '@png/box-min.png'

import useMedia from '@hooks/useMedia'

import sequence101 from '@jpg/sequence/101-min-min.jpg'
import sequence102 from '@jpg/sequence/102-min-min.jpg'
import sequence103 from '@jpg/sequence/103-min-min.jpg'
import sequence104 from '@jpg/sequence/104-min-min.jpg'
import sequence105 from '@jpg/sequence/105-min-min.jpg'
import sequence106 from '@jpg/sequence/106-min-min.jpg'
import sequence107 from '@jpg/sequence/107-min-min.jpg'
import sequence108 from '@jpg/sequence/108-min-min.jpg'
import sequence109 from '@jpg/sequence/109-min-min.jpg'
import sequence110 from '@jpg/sequence/110-min-min.jpg'
import sequence111 from '@jpg/sequence/111-min-min.jpg'
import sequence112 from '@jpg/sequence/112-min-min.jpg'
import sequence113 from '@jpg/sequence/113-min-min.jpg'
import sequence114 from '@jpg/sequence/114-min-min.jpg'
import sequence115 from '@jpg/sequence/115-min-min.jpg'
import sequence116 from '@jpg/sequence/116-min-min.jpg'
import sequence117 from '@jpg/sequence/117-min-min.jpg'
import sequence118 from '@jpg/sequence/118-min-min.jpg'
import sequence119 from '@jpg/sequence/119-min-min.jpg'
import sequence120 from '@jpg/sequence/120-min-min.jpg'
import sequence121 from '@jpg/sequence/121-min-min.jpg'
import sequence122 from '@jpg/sequence/122-min-min.jpg'
import sequence123 from '@jpg/sequence/123-min-min.jpg'
import sequence124 from '@jpg/sequence/124-min-min.jpg'
import sequence125 from '@jpg/sequence/125-min-min.jpg'
import sequence126 from '@jpg/sequence/126-min-min.jpg'
import sequence127 from '@jpg/sequence/127-min-min.jpg'
import sequence128 from '@jpg/sequence/128-min-min.jpg'
import sequence129 from '@jpg/sequence/129-min-min.jpg'
import sequence130 from '@jpg/sequence/130-min-min.jpg'
import sequence131 from '@jpg/sequence/131-min-min.jpg'
import sequence132 from '@jpg/sequence/132-min-min.jpg'
import sequence133 from '@jpg/sequence/133-min-min.jpg'
import sequence134 from '@jpg/sequence/134-min-min.jpg'
import sequence135 from '@jpg/sequence/135-min-min.jpg'
import sequence136 from '@jpg/sequence/136-min-min.jpg'
import sequence137 from '@jpg/sequence/137-min-min.jpg'
import sequence138 from '@jpg/sequence/138-min-min.jpg'
import sequence139 from '@jpg/sequence/139-min-min.jpg'
import sequence140 from '@jpg/sequence/140-min-min.jpg'
import sequence141 from '@jpg/sequence/141-min-min.jpg'
import sequence142 from '@jpg/sequence/142-min-min.jpg'
import sequence143 from '@jpg/sequence/143-min-min.jpg'
import sequence144 from '@jpg/sequence/144-min-min.jpg'
import sequence145 from '@jpg/sequence/145-min-min.jpg'
import sequence146 from '@jpg/sequence/146-min-min.jpg'
import sequence147 from '@jpg/sequence/147-min-min.jpg'
import sequence148 from '@jpg/sequence/148-min-min.jpg'
import sequence149 from '@jpg/sequence/149-min-min.jpg'
import sequence150 from '@jpg/sequence/150-min-min.jpg'
import sequence151 from '@jpg/sequence/151-min-min.jpg'
import sequence152 from '@jpg/sequence/152-min-min.jpg'
import sequence153 from '@jpg/sequence/153-min-min.jpg'
import sequence154 from '@jpg/sequence/154-min-min.jpg'
import sequence155 from '@jpg/sequence/155-min-min.jpg'
import sequence156 from '@jpg/sequence/156-min-min.jpg'
import sequence157 from '@jpg/sequence/157-min-min.jpg'
import sequence158 from '@jpg/sequence/158-min-min.jpg'
import sequence159 from '@jpg/sequence/159-min-min.jpg'
import sequence160 from '@jpg/sequence/160-min-min.jpg'

type props = {
  data: any
}

const Hero: React.FC<props> = ({data}) => {

  const preload = useMemo(() => {
    return ([
      sequence101,
      sequence102,
      sequence103,
      sequence104,
      sequence105,
      sequence106,
      sequence107,
      sequence108,
      sequence109,
      sequence110,
      sequence111,
      sequence112,
      sequence113,
      sequence114,
      sequence115,
      sequence116,
      sequence117,
      sequence118,
      sequence119,
      sequence120,
      sequence121,
      sequence122,
      sequence123,
      sequence124,
      sequence125,
      sequence126,
      sequence127,
      sequence128,
      sequence129,
      sequence130,
      sequence131,
      sequence132,
      sequence133,
      sequence134,
      sequence135,
      sequence136,
      sequence137,
      sequence138,
      sequence139,
      sequence140,
      sequence141,
      sequence142,
      sequence143,
      sequence144,
      sequence145,
      sequence146,
      sequence147,
      sequence148,
      sequence149,
      sequence150,
      sequence151,
      sequence152,
      sequence153,
      sequence154,
      sequence155,
      sequence156,
      sequence157,
      sequence158,
      sequence159,
      sequence160,
    ])
  }, []);

  const screen = useContext(ScreenContext)

  const [title2Trigger, setTitle2Trigger] = useState(false)
  const [title3Trigger, setTitle3Trigger] = useState(false)
  const [subTitle1Trigger, setSubTitle1Trigger] = useState(false)
  const [subTitle2Trigger, setSubTitle2Trigger] = useState(false)
  const loading = useRef(true)

  const tl1 = useRef(null)
  const tl2 = useRef(null)
  const animation1Played = useRef(false)
  const animation2Played = useRef(false)
  const animation = useRef(false)
  const lastTouch = useRef({clientY: 0})

  const wrapperRef = useRef(null)
  const contentRef = useRef(null)
  const content2Ref = useRef(null)
  const content3Ref = useRef(null)
  const subTitleRef = useRef(null)
  const boxRef = useRef(null)
  const background1Ref = useRef(null)
  const background2Ref = useRef(null)
  const background3Ref = useRef(null)

  const background1Width = useMedia("0vw", '0vw', '100%', '100%')
  const background1Height = useMedia('100%', '100%', '0vw', '0vw')
  const background2Width = useMedia('100%', '100%', '0vw', '')
  const background2Height = useMedia('0vw', '0vw', '100%', '')
  const imgObjPos = useMedia('unset', 'unset', 'unset', '8%')

  const stopScroll = () => {
    gsap.set("#___gatsby", {
      height: "101vh",
      overflow: "hidden",
    });
  }

  const startScroll = () => {
    gsap.set("#___gatsby", {
      height: "unset",
      overflow: "unset",
    });
  }

  const wheelEvent = (e) => {

    if (e.deltaY > 0) {
      if (!animation1Played.current && !animation.current) {
        tl1.current.play();
      } else if (!animation2Played.current && !animation.current) {
        tl2.current.play();
      } else if (
        animation1Played.current &&
        animation2Played.current &&
        !animation.current
      ) {
        startScroll();
        removeWheelEvent();
        addScrollTopEvent();
      }
    }
    if (e.deltaY < 0) {
      if (animation2Played.current && !animation.current) {
        animation.current = true;
        stopScroll();
        tl2.current.reverse(0);
      } else if (animation1Played.current && !animation.current) {
        animation.current = true;
        tl1.current.reverse(0);
      }
    }
  }

  const touchEndEvent = (e) => {
    let currentTouch = e.changedTouches[0].clientY;

    if (currentTouch < lastTouch.current) {
      if (!animation1Played.current && !animation.current) {
        tl1.current.play();
      } else if (!animation2Played.current && !animation.current) {
        tl2.current.play();
      } else if (
        animation1Played.current &&
        animation2Played.current &&
        !animation.current
      ) {
        startScroll();
        removeWheelEvent();
        addScrollTopEvent();
      }
    }
    if (currentTouch > lastTouch.current) {
      if (animation2Played.current && !animation.current) {
        animation.current = true;
        stopScroll();
        tl2.current.reverse(0);
      } else if (animation1Played.current && !animation.current) {

        animation.current = true;
        tl1.current.reverse(0);
      }
    }
  }

  const touchStartEvent = (e) => {
    lastTouch.current = e.touches[0].clientY;
  }

  const addWheelEvent = () => {
    window.addEventListener('wheel', wheelEvent)
    window.addEventListener('touchstart', touchStartEvent)
    window.addEventListener('touchend', touchEndEvent)
    window.removeEventListener('scroll', scrollTopEvent)
  }

  const removeWheelEvent = () => {
    window.removeEventListener("wheel", wheelEvent);
    window.removeEventListener("touchstart", touchStartEvent);
    window.removeEventListener("touchend", touchEndEvent);
  }

  const scrollTopEvent = (e) => {
    if (window.scrollY === 0) {
      stopScroll();
      addWheelEvent();
    }
  }

  const addScrollTopEvent = () => {
    window.addEventListener("scroll", scrollTopEvent);
  }

  useEffect(() => {

    if (!screen.mobile && background1Width && background1Height && background2Height && background2Width) {
      stopScroll()

      let scroll1Tl
      let scroll2Tl

      if (!tl1.current) {
        scroll1Tl = gsap.timeline({
          paused: true,
          onStart: () => {
            animation.current = true
          },
          onComplete: () => {
            animation.current = false
            animation1Played.current = true
          },
          onReverseComplete: () => {
            animation.current = false
            animation1Played.current = false
          }
        })
    
        scroll1Tl.to(background1Ref.current, {
          duration: 1.5,
          width: background1Width,
          height: background1Height,
          ease: "circ.inOut"
        }, 0)
        scroll1Tl.to(background2Ref.current, {
          duration: 1.5,
          width: background2Width,
          height: background2Height,
          ease: "circ.inOut"
        }, 0)
        scroll1Tl.to(background3Ref.current, {
          duration: 1.5,
          height: background2Height,
          width: background2Width,
          ease: "circ.inOut"
        }, 0)
        scroll1Tl.to(boxRef.current, {
          duration: 0.5,
          opacity: 0,
        }, 0.7)
        scroll1Tl.to(contentRef.current, {
          duration: 1.5,
          opacity: 0
        }, 0)
        scroll1Tl.call(setSubTitle1Trigger, [true], 0.4)
        scroll1Tl.call(setTitle2Trigger, [true], 0.4)
        tl1.current = scroll1Tl
      }

      if (!tl2.current) {
        scroll2Tl = gsap.timeline({
          paused: true,
          onStart: () => {
            animation.current = true
          },
          onComplete: () => {
            animation2Played.current = true
            animation.current = false
          },
          onReverseComplete: () => {
            animation.current = false
            animation2Played.current = false
          }
        })
        scroll2Tl.fromTo(content2Ref.current, {
          opacity: 1
        }, {
          duration: 1,
          opacity: 0
        }, 0)
        
        scroll2Tl.to('.sequence', {
          duration: 2,
          objectPosition: imgObjPos,
        }, 1)
        scroll2Tl.fromTo('.sequence', {
          visibility: 'visible'
        }, {
          stagger: 0.033,
          duration: 0.1,
          visibility: 'hidden'
        }, 1)
        scroll2Tl.fromTo(content3Ref.current, {
          opacity: 0
        }, {
          duration: 1,
          opacity: 1
        }, 2)
  
        scroll2Tl.call(setSubTitle2Trigger, [true], 2.5)
        scroll2Tl.call(setTitle3Trigger, [true], 2.5)
        tl2.current = scroll2Tl
      }

      addWheelEvent()
      return () => {
        if (tl1.current) {
          tl1.current.kill()
        }
        if (tl2.current) {
          tl2.current.kill()
        }
        removeWheelEvent()
        startScroll()
        window.removeEventListener('scroll', scrollTopEvent)
      }

    } else {
      setSubTitle1Trigger(true)
      setTitle2Trigger(true)
      setSubTitle2Trigger(true)
      setTitle3Trigger(true)
      gsap.set(content3Ref.current, {
        opacity: 1
      })
    }

  }, [screen, background1Width, background1Height, background2Height, background2Width]);

  const imageTags = useMemo(() => preload.map((item, index, arr) => {
    return (
      <Img
        className={index !== arr.length - 1 ? "sequence" : "last"}
        zIndex={arr.length - index}
        src={item}
        key={index}
        alt={`image sequence ${index}`}
      />
    );
  }), [preload]);

  const titleTextArray = useMedia(
    "The leading\ntechnology in\nadvanced pipeline\nmonitoring\n",
    "The leading\ntechnology in\nadvanced pipeline\nmonitoring\n",
    "The leading\ntechnology in\nadvanced pipeline\nmonitoring\n",
    "The leading\ntechnology & in\nadvanced\npipeline\nmonitoring\n"
  );

  const title3TextArray = useMedia(
    [
      () => "Fast, accurate",
      () => "analytics reporting to",
      () => "strengthen your",
      () => "strategic planning.",
    ],
    [
      () => "Fast, accurate",
      () => "analytics reporting to",
      () => "strengthen your",
      () => "strategic planning.",
    ],
    [
      () => "Fast, accurate",
      () => "analytics reporting to",
      () => "strengthen your",
      () => "strategic planning.",
    ],
    [
      () => "Fast, accurate",
      () => "analytics reporting",
      () => "to strengthen your",
      () => "strategic planning.",
    ]
  );


  return (
    <Wrapper ref={wrapperRef} id="miqroaware-hero">
      <Box 
        ref={boxRef} 
        src={BoxPNG}
        // srcSet={data.boxPNG.childImageSharp.fluid.srcSet}
        loading="eager"
        alt="box"
      />

      {!screen.mobile && imageTags}
      <Background1 ref={background1Ref}>
        <Content ref={contentRef}>
          <SubTitle ref={subTitleRef}>mIQroAware&trade;</SubTitle>
          <HR />
          <Title>{titleTextArray}</Title>
          <Text>One comprehensive system to detect and report on every major risk for pipelines.</Text>
        </Content>
      </Background1>
      <Background2 ref={background2Ref} />
      <Background3 ref={background3Ref} />
     

      <Content2 ref={content2Ref}>
        {screen.mobile && <img src={sequence101} alt="box image 1"/>}
        <SubTitle2>
          <TextAnimation
            textArray={[() => "Proactive Asset Protection"]}
            className="miqroaware-hero-sub-1"
            height={useMedia("1.667vw", "1.667vw", "2.9vw", "6.4vw")}
            trigger={subTitle1Trigger}
          />
        </SubTitle2>
        <Title2>
          <TextAnimation
            textArray={[
              () => "Pipeline integrity",
              () => "monitored by a",
              () => "patented suite of",
              () => "smart sensors.",
            ]}
            className="miqroaware-hero-title2"
            trigger={title2Trigger}
            height={useMedia("4.167vw", "4.167vw", "7.186vw", "9.6vw")}
          />
        </Title2>
      </Content2>

      <Content3 ref={content3Ref}>
        {screen.mobile && <img className="last" src={sequence160} alt="box image 2"/>}
        <SubTitle3>
          <TextAnimation
            textArray={[() => "Instant Intelligent Insights"]}
            className="miqroaware-hero-sub-2"
            height={useMedia("1.667vw", "1.667vw", "2.9vw", "6.4vw")}
            trigger={subTitle2Trigger}
            justifyContent={"flex-end"}
          />
        </SubTitle3>
        <Title3>
          <TextAnimation
            textArray={title3TextArray || []}
            className="miqroaware-hero-title3"
            trigger={title3Trigger}
            height={useMedia("4.167vw", "4.167vw", "7.186vw", "9.6vw")}
            justifyContent={"flex-end"}
          />
        </Title3>
      </Content3>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.section`
  background-color: ${colors.black};
  position: relative;
  width: 100vw;
  min-height: 100vh;
  height: 54.722vw;

  ${media.tablet} {
    min-height: unset;
    height: 142.994vw;
  }

  ${media.mobile} {
    min-height: unset;
    height: auto;
    // overflow: hidden;
  }
`;

const Img = styled.img<{ zIndex: number }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.zIndex};

  ${media.tablet} {
    bottom: 0;
    left: 0;
    top: unset;
    object-position: center center;
    object-fit: cover;
    height: 76.713vw;
  }

  ${media.mobile} {
    bottom: 0;
    left: 0;
    top: unset;
    height: 93vw;
    object-position: 75%;

    &.last {
      object-position: 8%;
    }
  }
`;

const Background1 = styled.div`
  position: absolute;
  background-color: ${colors.culturedWhite20};
  left: 0;
  top: 0;
  z-index: 70;

  height: 100%;
  width: 55.208vw;

  ${media.tablet} {
    height: 92.934vw;
    width: 100vw;
  }

  ${media.mobile} {
    position: relative;
    width: 100vw;
    height: 157.333vw;
  }
`;

const Background2 = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 70;
  background-color: ${colors.culturedWhite20};

  width: 100%;
  height: 7.708vw;

  ${media.tablet} {
    height: 142.994vw;
    bottom: 0vw;
    top: unset;
    left: 0vw;
    width: 5.988vw;
    display: none;
  }

  ${media.mobile} {
    display: none;
  }
`;

const Background3 = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 70;
  background-color: ${colors.culturedWhite20};
  width: 100%;
  height: 3.403vw;

  ${media.tablet} {
    height: 142.994vw;
    bottom: 0vw;
    right: 0vw;
    left: unset;
    width: 5.988vw;
    display: none;
  }

  ${media.mobile} {
    display: none;
  }
`;

const Box = styled.img`
  position: absolute;
  z-index: 80;
  left: 0;
  top: 0;

  object-fit: cover;
  width: 100vw;
  min-height: 100vh;
  height: 54.722vw;

  ${media.tablet} {
    width: 100vw;
    object-position: center center;
    object-fit: cover;
    height: 76.713vw;
    min-height: unset;
    top: unset;
    bottom: 0;
  }

  ${media.mobile} {
    bottom: unset;
    left: 0vw;
    top: 125vw;
    height: 93vw;
    min-height: unset;
    object-position: 75%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-top: 8.944vw;
  padding-left: 3.472vw;
  padding-right: 5.694vw;

  ${media.tablet} {
    padding-top: 18.084vw;
    padding-left: 5.988vw;
    padding-right: 5.988vw;
  }

  ${media.mobile} {
    padding-top: 30.267vw;
    padding-left: 6.667vw;
    padding-right: 5.667vw;
  }
`;

const SubTitle = styled.h2`
  color: ${colors.black};
  opacity: 1;

  ${text.desktopXXSHeading}

  ${media.tablet} {
    ${text.tabletXXSHeading}
  }

  ${media.mobile} {
    ${text.mobileBodyCopy1}
  }
`;

const HR = styled.hr`
  background-color: ${colors.black};
  height: 1px;
  border: none;
  outline: none;
  width: 100%;

  margin-top: 1.389vw;
  margin-bottom: 1.389vw;

  ${media.tablet} {
    margin-top: 2.395vw;
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    margin-top: 4.533vw;
    margin-bottom: 4.333vw;
  }
`;

const Title = styled.h1`
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;

  ${text.desktopLHeading}
  width: 43.611vw;
  margin-bottom: 2.083vw;

  ${media.tablet} {
    ${text.tabletLHeading}
    width: 75.21vw;
    margin-bottom: 3.593vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    width: 100.333vw;
    margin-bottom: 5vw;
  }
`;

const Text = styled.p`
  color: ${colors.black};
  display: flex;
  flex-direction: column;

  ${text.desktopBodyCopy1}
  width: 22.500vw;

  ${media.tablet} {
    ${text.tabletBodyCopy1}
    width: 38.802vw;
  }

  ${media.mobile} {
    ${text.mobileBodyCopy1}
    width: 100%;
  }
`;

const Content2 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 69;

  width: 38.194vw;
  left: 11.458vw;
  top: 16.458vw;

  ${media.tablet} {
    width: 65.868vw;
    left: 13.413vw;
    top: 20vw;
  }

  ${media.mobile} {
    height: 145.533vw;
    position: relative;
    width: 100vw;
    top: -32.1vw;
    left: unset;

    img {
      width: 100vw;
      object-fit: cover;
      height: 93vw;
      object-position: 75%;
    }
  }
`;

const Title2 = styled.h2`
  color: ${colors.culturedWhite60};
  display: flex;
  flex-direction: column;

  ${text.desktopMHeading}

  ${media.tablet} {
    ${text.tabletMHeading}
  }

  ${media.mobile} {
    ${text.mobileXSHeading}
    margin-left: 5.6vw;
  }
`;

const SubTitle2 = styled.h3`
  color: ${colors.darkOrange100};
  display: flex;
  flex-direction: column;

  ${text.desktopPetiteHeading}
  margin-bottom: 1.389vw;

  ${media.tablet} {
    ${text.tabletPetiteHeading}
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobilePetiteHeading}
    margin-bottom: 5.333vw;
    margin-left: 5.6vw;
  }
`;

const Content3 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: 0;
  z-index: 69;

  width: 39.167vw;
  right: 11.319vw;
  top: 16.458vw;

  ${media.tablet} {
    width: 67.545vw;
    top: 22.395vw;
    right: 13.413vw;
  }

  ${media.mobile} {
    position: static;
    width: 100vw;
    position: static;

    img {
      width: 100vw;
      object-fit: cover;
      height: 93vw;
      object-position: 75%;

      &.last {
        object-position: 8%;
        transform: scale(1.3);
      }
    }
  }
`;

const SubTitle3 = styled.h3`
  color: ${colors.darkOrange100};
  text-align: right;
  display: flex;
  width: 100%;
  flex-direction: column;

  ${text.desktopPetiteHeading}
  margin-bottom: 1.389vw;

  ${media.tablet} {
    ${text.tabletPetiteHeading}
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobilePetiteHeading}
    margin-bottom: 5.333vw;
    margin-right: 6.667vw;
    position: relative;
    z-index: 2;
  }
`;

const Title3 = styled.h2`
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 100%;
  color: ${colors.culturedWhite60};

  ${text.desktopMHeading}

  ${media.tablet} {
    ${text.tabletMHeading}
  }

  ${media.mobile} {
    ${text.mobileXSHeading}
    margin-right: 6.667vw;
    position: relative;
    z-index: 2;
  }
`;
