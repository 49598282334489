import React from "react";
import loadable from "@loadable/component";
import { graphql } from "gatsby";

import SEO from "@components/SEO";

import colors from "@styles/colors";

import Hero from "@sections/miqroaware/Hero";

const Header = loadable(() => import("@components/Header"))
const Features = loadable(() => import("@sections/miqroaware/Features"));
const Benefits = loadable(() => import("@sections/miqroaware/Benefits"));
const Insights = loadable(() => import("@sections/miqroaware/Insights"));
const HowItWorks = loadable(() => import("@sections/miqroaware/HowItWorks"));
const Learn = loadable(() => import("@sections/miqroaware/Learn"));
const WhyChoose = loadable(() => import("@sections/miqroaware/WhyChoose"));
const PartnerCTA = loadable(() => import("@sections/miqroaware/PartnerCTA"));

type props = {
  data: any
}

const Miqroaware: React.FC<props> = ({data}) => {
  return (
    <>
      <Header 
        backgroundTrigger={"#miqroaware-hero"} 
        startingTextColor={colors.black}
        startingButtonColor={colors.keppel100}
        startingButtonBorderColor={colors.keppel100}
      />
      <SEO title="MiqroAware | mIQrotech" />
      <Hero data={data} />
      <Features  />
      <Benefits  />
      <Insights  />
      <HowItWorks  />
      <Learn  />
      <WhyChoose  />
      <PartnerCTA  />
    </>
  );
};

export default Miqroaware;


// export const query = graphql`
//   query {
//     boxPNG: file(relativePath: { eq: "png/box-min.png" }) {
//       childImageSharp {
//         fluid(pngQuality: 20) {
//           srcSet
//         }
//       }
//     }
//   }
// `;
